import { ITableColumn } from '../interfaces/table-column.interface';
import { featuresWithPermissions } from './permissions';

export const scrollableColumns: ITableColumn[] = [
    { field: 'userId', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.USER_ID', sortable: true, editable: false, checked: true },
    { field: 'productId', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PROD_ID', sortable: true, editable: false, checked: true },
    { field: 'sta_year', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.STA_YEAR', sortable: true, editable: false, checked: true },
    {
        field: 'contactOwner',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CONTACT_OWNER',
        sortable: true,
        editable: false,
        checked: true,
    },
    { field: 'status', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TASTING_STATUS', sortable: true, editable: false, checked: true },
    { field: 'orderdate', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ORDER_DATE', sortable: true, editable: false, checked: true },
    { field: 'season', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SEASON', sortable: true, editable: true, checked: true },
    { field: 'cn_code', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CN_CODE', sortable: false, editable: true, checked: true },
    { field: 'iti_class', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ITI_CLASS', sortable: false, editable: true, checked: true },
    {
        field: 'transport_conditions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TRANSPORT_CONDITIONS',
        sortable: false,
        editable: true,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'ingredients_list',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.INGREDIENTS_LIST',
        sortable: false,
        editable: true,
        checked: true,
    },
    {
        field: 'bc_comments',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMMENTS_FIELD',
        sortable: false,
        editable: false,
        checked: true,
    },
    { field: 'categoryName', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CAT_NAME', sortable: true, editable: false, checked: true },
    {
        field: 'hasCommentsAndSuggestions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMMENTS_SUGGESTIONS',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'hasFoodPairing',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.FOOD_PAIRING',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'hasQuestions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPECIFIC_QUESTIONS',
        sortable: false,
        editable: true,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'feedback_only',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.FEEDBACK_ONLY',
        sortable: false,
        editable: true,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'new_client',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.NEW_CLIENT',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'past_stars',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PAST_STARS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'candidate_award',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CANDIDATE_AWARD',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'packshot',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PACKSHOT',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'logo',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.LOGO',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'payment_status',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PAYMENT_STATUS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    { field: 'shelf_life', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SHELF_LIFE', sortable: true, editable: true, checked: true },
    { field: 'sample_arrival_deadline', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SAMPLE_ARRIVAL_DEADLINE', sortable: true, editable: true, checked: true },
    {
        field: 'client_delivery_instructions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_DELIVERY_INSTRUCTIONS',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'samples_delivered',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_STATUS',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'delivery_date',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_DATE',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'delivery_status',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SAMPLES_DELIVERY_STATUS',
        sortable: true,
        editable: false,
        checked: true,
    },
    {
        field: 'comment_about_delivery',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DELIVERY_COMMENTS',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'specific_planning',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPECIFIC_PLANNING',
        sortable: true,
        editable: true,
        checked: true,
    },
    {
        field: 'client_spec',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_SPECS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'bc_specs',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.BC_SPECS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'description',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DESCRIPTION',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'announcementId',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ANNOUNCEMENT_ID',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'announcementDescription',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ANNOUNCEMENT_DESC',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'clientInstructions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLIENT_INSTRUCTIONS',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'bcInstructions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.BC_INSTRUCTIONS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'non_standard_ingredient',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.NON_STANDARD_INGREDIENT',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'claims',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.CLAIMS',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'storage_conditions',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.STORAGE_CONDITIONS',
        sortable: false,
        editable: false,
        checked: true,
    },
    {
        field: 'defrosting_time',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.DEFROSTING_TIME',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'alcoholLevel',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ALCOHOL_LEVEL',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'spiciness_level',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.SPICINESS_LEVEL',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'planning_id',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PLANNING_ID',
        sortable: false,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'planning_details',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PLANNING_DETAILS',
        sortable: true,
        editable: false,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
    {
        field: 'action',
        header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.ACTION',
        sortable: false,
        editable: true,
        checked: true,
        colPermission: featuresWithPermissions.pretest_full_column_access,
    },
];

export const frozenColumns: ITableColumn[] = [
    { field: 'checkbox', header: '', sortable: false, editable: false, checked: true },
    { field: 'tastingId', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.TASTING_ID', sortable: true, editable: true, checked: true },
    { field: 'companyName', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.COMPANY_NAME', editable: true, checked: true },
    { field: 'productName', header: 'MY_WORKSPACE.PRE_TEST.TABLE.COL_HEADER.PROD_NAME', sortable: false, editable: true, checked: true },
];
