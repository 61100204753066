// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { IEnvironment } from '../shared/interfaces/environment.interface';

export const environment: IEnvironment = {
    production: false,
    itqiImageUrl: 'https://prod.frontitqi.dev.itw.dgnus.net:8080',
    itqiApi: 'https://itqi.dev.itw.dgnus.net:8080/front/',
    itqiFiles: 'https://itqi.dev.itw.dgnus.net:8080',
    itqiIngredientsListPrefixPath: 'https://itqi.dev.itw.dgnus.net:8080',
    frontFacingSiteUrl: 'https://prod.frontitqi.dev.itw.dgnus.net',

    headerAccept: 'vdn.itqi.v1',
    headerAuthorization: 'PKttAuh4uHGskCNVh',
    mock: {
        all: false,
        enable: true,
        services: {
            auth: false,
            profile: false,
            products: false,
            pretest: false,
            pretestTranslations: false,
            resultsValidation: false,
            resultsAndReports: false,
            reportRedactionTool: false,
            reportDetails: false,
            resultValidationDetails: false,
        },
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
